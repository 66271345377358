import { string, number } from 'prop-types';
import * as styles from './legal-journey.module.scss';
import tick from '../../assets/icons/tick.svg';

function Circle({
  step, currentStep, text, uri,
}) {
  const active = step === currentStep;
  const done = step < currentStep;
  return (
    <a href={uri} className={done ? styles.complete : styles.step}>
      <div className={active ? styles.circleActive : styles.circle}>
        <div className={styles.number}>
          {done ? (
            <img src={tick} alt="tick icon" />
          ) : step }

        </div>
      </div>
      <span className={styles.text}>{text}</span>
    </a>

  );
}

Circle.defaultProps = {
  step: 0,
  currentStep: 0,
  text: '',
  uri: '',
};

Circle.propTypes = {
  step: number,
  currentStep: number,
  text: string,
  uri: string,
};

export { Circle };
