import { object } from 'prop-types';
import { Link } from 'gatsby';
import { Wrap } from '../../../sections-wrap';
import * as styles from './index.module.scss';

function Section({ data }) {
  return (
    <Wrap>
      <div className={styles.section}>
        <Link to={data.wpParent?.node?.uri} className={styles.link}>
          {data.wpParent?.node?.title}
        </Link>
        {' '}
        &gt;
        {' '}
        <Link to={data.uri} className={styles.link}>
          {data.template?.practiceChildConfiguration?.eaPracticeShortName}
        </Link>
      </div>
    </Wrap>
  );
}

Section.defaultProps = {
  data: {},
};

Section.propTypes = {
  data: object,
};

export { Section };
