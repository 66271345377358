import { string, number, bool } from 'prop-types';
import { classNames } from '../../../helpers';
import * as styles from './index.module.scss';

function LinearProgress({
  label, currentStep, steps, className, showContainer,
}) {
  const setContainer = () => {
    if (currentStep === 1) {
      if (showContainer) {
        return styles.containerWithMarginAndBackground;
      }
      return styles.containerWithMargin;
    }
    if (showContainer) {
      return styles.containerWithBackground;
    }
    return styles.container;
  };

  return (
    <div className={setContainer()}>
      <div className={styles.label}>
        {label}
      </div>
      <div className={styles.progressBarContainer}>
        <div className={styles.linear}>
          <hr className={styles.barContainer} />
          <hr
            style={{ width: `${(currentStep / steps) * 100}%` }}
            className={classNames([styles.bar, className || styles.defaultColor])}
          />
        </div>
        <div className={styles.percentage}>
          {currentStep}
          /
          {steps}
        </div>
      </div>
    </div>
  );
}

LinearProgress.propTypes = {
  label: string,
  currentStep: number,
  steps: number,
  className: string,
  showContainer: bool,
};

LinearProgress.defaultProps = {
  label: 'Steps',
  currentStep: 0,
  steps: 0,
  className: '',
  showContainer: false,
};

export { LinearProgress };
