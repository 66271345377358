import { string } from 'prop-types';
import { useState } from 'react';
import { Button } from '../button';
import { classNames } from '../../../helpers';
import * as styles from './index.module.scss';
import { ZoomCalendar } from '../../zoom-calendar';

function StartFreeCase({ background, firstButton, secondButton }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
      <ZoomCalendar onClose={() => setOpen(false)} />
      )}
      <div className={classNames([styles.startCaseContainer, background])}>
        <span className={styles.text}>
          Start your
          {' '}
          Case Assesment
        </span>
        <div className={styles.buttonsContainer}>
          <a href="tel:303-688-0944">
            <Button
              text="Call Now"
              buttonStyle={firstButton}
            />
          </a>
          <div className={styles.scheduleButton}>
            <Button
              text="Schedule"
              buttonStyle={secondButton}
              className={styles.buttonStyle}
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
        <span className={styles.textAux}>
          Find out your legal options.
        </span>
      </div>
    </>
  );
}

StartFreeCase.propTypes = {
  background: string,
  firstButton: string,
  secondButton: string,
};

StartFreeCase.defaultProps = {
  background: '',
  firstButton: 'primary',
  secondButton: 'tertiary',
};

export { StartFreeCase };
