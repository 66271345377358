import { number, string } from 'prop-types';
import * as styles from './legal-journey.module.scss';
import { Wrap } from '../sections-wrap';
import { LinearProgress } from '../common/linear-progess-bar';
import { Circle } from './circle';

function LegalJourney({ currentStep, steps, uri }) {
  return (
    <Wrap>
      <div className={styles.journeyContainer}>

        <div className={styles.header}>
          <h2>
            {currentStep === 0 && 'Start'}
            {' '}
            Your Legal Journey
          </h2>
          <h6>Navigate using the steps below</h6>
        </div>
        <div className={styles.contentContainer}>
          <Circle step={1} currentStep={currentStep} text="Learn about your legal problem." uri={`${uri}step-1/`} />
          <Circle step={2} currentStep={currentStep} text="Choose your lawyer." uri={`${uri}step-2/`} />
          <Circle step={3} currentStep={currentStep} text="How much will this cost?" uri={`${uri}step-3/`} />
          <Circle step={4} currentStep={currentStep} text="Make an appointment." uri={`${uri}step-4/`} />
        </div>
        <LinearProgress label="Steps" currentStep={currentStep} steps={steps} />
      </div>
    </Wrap>

  );
}

LegalJourney.defaultProps = {
  currentStep: 0,
  steps: 0,
  uri: '',
};

LegalJourney.propTypes = {
  currentStep: number,
  steps: number,
  uri: string,
};

export { LegalJourney };
