import { object } from 'prop-types';
import { Wrap } from '../../../sections-wrap';
import { StartFreeCase } from '../../../common/start-free-case';
import * as styles from './index.module.scss';

function Header({ data }) {
  return (
    <Wrap>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>
            {data?.eaPracticeShortName}
          </h1>
        </div>
        <div className={styles.introContainer}>
          <div className={styles.mainContent}>
            <h2 className={styles.content}>{data?.eaPracticeChildIntroTitle}</h2>
          </div>
          <StartFreeCase firstButton="primaryTextBlue" />
        </div>
      </div>
    </Wrap>
  );
}

Header.propTypes = {
  data: object,
};

Header.defaultProps = {
  data: {},
};

export { Header };
