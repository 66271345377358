import {
  string, oneOf, func, node, bool,
} from 'prop-types';
import { classNames } from '../../../helpers';
import * as styles from './index.module.scss';

function Button({
  className, buttonStyle, text, onClick, startContent, endContent, disabled,
}) {
  const renderContent = (content) => (<div>{content}</div>);

  return (
    <button
      className={classNames([className, styles[buttonStyle]])}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >

      <div className={styles.buttonContent}>
        {startContent && renderContent(startContent)}
        {text && <span>{text}</span>}
        {endContent && renderContent(endContent)}
      </div>
    </button>
  );
}

Button.propTypes = {
  className: string,
  buttonStyle: oneOf(['primary', 'primaryTextBlue', 'secondary', 'tertiary', 'primaryAlimony', 'secondaryAlimony']),
  text: string.isRequired,
  onClick: func,
  startContent: node,
  endContent: node,
  disabled: bool,
};

Button.defaultProps = {
  className: styles.defaultStyle,
  buttonStyle: 'primary',
  startContent: null,
  onClick: null,
  endContent: null,
  disabled: false,
};

export { Button };
