import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Header } from '../components/pages/practice-child-template/header';
import { LegalJourney } from '../components/legal-journey';
import { Section } from '../components/common/practice-child-step/section';
import { Browse } from '../components/browse';
import { LawyersSection } from '../components/lawyers-section';
import * as styles from './templates.module.scss';
import { areBlogPostsInBothCountries, blogPostsFiltered, sortArticlesByDate } from '../helpers';
import { getTagsFromPosts } from '../helpers/misc';

function PracticeChildTemplate({ data }) {
  const titlePage = data?.wpPage?.title;
  const dataText = data?.wpPage?.template?.practiceChildConfiguration;
  const { eaChildShortName: sectionName } = dataText;

  let browseData = data?.relatedPosts.nodes && data?.relatedPosts.nodes.length > 0
    ? data?.relatedPosts.nodes : data?.wpPage?.practiceToPostsConnection?.nodes;
  browseData = browseData?.sort((a, b) => (a.title?.localeCompare(b.title)));

  const tools = browseData.filter(
    (item) => item.categories.nodes.filter(
      (node) => node.databaseId.toString() === process.env.GATSBY_EA_TOOLS_CATEGORY_ID,
    ).length,
  );
  let blogPosts = blogPostsFiltered(browseData);
  blogPosts = sortArticlesByDate(blogPosts);
  const tags = getTagsFromPosts(browseData);

  const caseResults = data?.wpPage?.caseResultsSnippets?.nodes;
  const browse = [
    {
      name: 'Blogs', type: 'Article', children: blogPosts,
    },
    { name: 'Topics', type: 'Index', children: tags },
    {
      name: 'Tools', type: 'Text', maxItems: 6, children: tools,
    },
    {
      name: 'Results', type: 'Case', children: caseResults, attorneys: data?.attorneys,
    },
  ];

  browse?.forEach((tab, i) => {
    if (tab.type !== 'Case') {
      browse[i].texas = areBlogPostsInBothCountries(tab.children);
    }
  });
  const lawyersData = data?.wpPage?.wpParent?.node?.practiceToTeamConnection.nodes;

  return (
    <main className={styles.pageStyles}>
      <Section data={data?.wpPage} />
      <Header data={data?.wpPage?.template?.practiceChildConfiguration} />
      {lawyersData?.length && (
      <LawyersSection
        data={lawyersData}
        className={styles.attorneysContainer}
      />
      )}
      <Browse
        title={sectionName ? `${sectionName} Resources` : `${titlePage} Resources`}
        data={browse}
        className={styles.browseContainer}
      />
      <LegalJourney currentStep={0} steps={4} />
    </main>
  );
}

export const query = graphql`
  query wpChildTemplate($uri: String, $databaseIds: [Int]) {
    relatedPosts: allWpPost(filter: {categories: {nodes: {elemMatch: {databaseId: {in: $databaseIds}}}}}
      sort: {order: DESC, fields: date}
    )
     {
      nodes {
        id
        title
        uri
        date
        schemaMarkup {
          eaPostSchemaUploadDate
          eaPostSchemaVideoName
        }
        excerpt
        featuredImage {
          node {
            gatsbyImage(width:400)
          }
        }
        tags {
            nodes {
              uri
              name
            }
          }
        categories {
          nodes {
            databaseId
            wpParent {
              node {
                name
                wpParent {
                  node {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage(uri: {eq: $uri}) {
      ...SeoPage
      title
      uri
      template {
        ... on WpTemplate_PracticeChild {
          practiceChildConfiguration {
            eaPracticeChildIntroTitle
            eaPracticeChildIntroContent
            eaPageTitleExtraContent
            eaPracticeShortName
            eaPracticeChildIntroImage {
              gatsbyImage(width:758)
            }
          }
        }
      }
      practiceToPostsConnection {
        nodes {
          id
          title
          uri
          date
          schemaMarkup {
            eaPostSchemaUploadDate
            eaPostSchemaVideoName
          }
          excerpt
          featuredImage {
            node {
              gatsbyImage(width:400)
            }
          }
          tags {
            nodes {
              uri
              name
            }
          }
          categories {
            nodes {
              databaseId
              wpParent {
                node {
                  name
                  wpParent {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      caseResultsSnippets {
        nodes {
          title
          uri
          content
          caseResultSnippetSingleConfiguration {
            beCrSnippetClickthroughText
            beCrSnippetClickthroughUrl
            beCrSnippetAttorney {
              ... on WpTeamMember {
                title
                uri
              }
            }
            beCrSnippetPracticeArea {
              __typename
                ... on WpPage {
                  id
                  title
                  slug
                  template {
                    templateName
                    ... on WpTemplate_PracticeChild {
                      practiceChildConfiguration {
                        eaPracticeShortName
                      }
                    }
                  }
                }
              }
          }
        }
      }
      wpParent {
        node {
          ... on WpPage {
            title
            uri
            template {
              ... on WpTemplate_PracticeChild {
                practiceChildConfiguration {
                  eaPracticeShortName
                }
              }
            }
            practiceToTeamConnection {
              nodes {
                title
                uri
                featuredImage {
                  node {
                    gatsbyImage(width:200)
                  }
                }
                attorneyTeamSingleConfiguration {
                  eaTeamSpecialty
                  eaTeamQuickBio
                }
              }
            }
          }
        }
      }
    }
    attorneys: allContentfulPageAttorney {
      nodes {
        title
        uri
        attorney{
          attorneyProfileImage {
            image {
              gatsbyImage(width:45, placeholder: NONE)
              file {
                url
              }
            }
            altText
          }
          firstName
          lastName
        }
      }
    }
  }
`;

PracticeChildTemplate.propTypes = {
  data: object,
};

PracticeChildTemplate.defaultProps = {
  data: {},
};

export default PracticeChildTemplate;
export { Head } from '../components/seo/seo';
